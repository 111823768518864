<template>
  <div>
    <div slot="header"><h4>Discover Tile</h4></div>

    <div class="form-group row justify-content-center">
      <label :for="headlineId" class="text-right col-sm-4 col-form-label"
        >Headline</label
      >
      <div class="col-sm-6">
        <input
          class="form-control"
          :id="headlineId"
          v-model="discoveryTile.headline"
          placeholder="Tile Headline (max. 34 characters)"
          required
          maxlength="34"
        />
      </div>
    </div>

    <div class="form-group row justify-content-center">
      <label :for="bgId" class="text-right col-sm-4 col-form-label">Preview Image</label>
      <span v-if="discoveryTile.signed_media" class="btn btn-light h-full">
        <a :href="discoveryTile.signed_media" target="_blank"><i class="fas fa-external-link"></i></a>
      </span>
      <div class="col-sm-6">
        <vue-dropzone
          :awss3="awss3"
          @vdropzone-file-added="fileUploadStarted"
          @vdropzone-success="fileUploadCompleted"
          :id="bgId"
          :options="dropOptions()"
          ref="myVueDropzone"
        ></vue-dropzone>
        <span v-if="imgMetaData"
          >Size: {{ imgMetaData.fileWidth }} x
          {{ imgMetaData.fileHeight }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { UTILS, VALIDATION } from "../../utils/campaign_form.js";

export default {
  name: "DiscoverTile",
  props: {
    adsetNum:         Number,
    discoveryTile:    Object,
    platform_options: Object
  },
  data: function() {
    return {
      imgMetaData: null
    };
  },
  computed: {
    bgId()        { return `tile-bg-${this.adsetNum}`     },
    headlineId()  { return `tile-headline-${this.adsetNum}` },
    awss3: function() {
      return {
        signingURL: f => `${this.host}/ads/presigned_post?file_name=${f.name}`,
        headers: { Authorization: localStorage.getItem("token") },
        params: {},
        sendFileToServer: false
      };
    }
  },
  mounted() {
    if (!this.discoveryTile.media_bucket) this.discoveryTile.media_bucket = 'imgn-publishers'
    this.spreadDiscoveryTile(this.discoveryTile || {})
    this.registerEvents()
  },
  methods: {
    dropOptions() {
      return {
        acceptedFiles: "image/*",
        maxFilesize: 30,
        maxFiles: 1,
        timeout: 180000,
        addRemoveLinks: true,
        autoProcessQueue: true,
        resize: function(file) {
          let info;
          info = {
            srcX: 0,
            srcY: 0,
            srcWidth: file.width,
            srcHeight: file.height,
            trgX: 0,
            trgY: 0,
            trgWidth: this.options.thumbnailWidth,
            trgHeight: parseInt(
              (this.options.thumbnailWidth * file.height) / file.width
            )
          };

          return info;
        },
        headers: {},
        accept: VALIDATION.bg_tile_file(this.platform_options)
      };
    },
    async fileUploadCompleted(file) {
      this.discoveryTile.media_key    = file.s3Signature.key;
      this.discoveryTile.media_bucket = this.discoveryTile.media_bucket || 'imgn-publishers';
      this.imgMetaData = { fileWidth: file.width, fileHeight: file.height }

      this.discoveryTile.signed_media =
        await UTILS.getSignedMedia(this.$http, this.host, this.discoveryTile.media_key, this.discoveryTile.media_bucket)
      this.$eventHub.$emit("fileIsUploading", false, this.bgId)
    },
    fileUploadStarted: function() {
      this.$eventHub.$emit("fileIsUploading", true, this.bgId);
    },
    async spreadDiscoveryTile(discoveryTile) {
      Object.keys(discoveryTile).forEach(key => { this[key] = discoveryTile[key] });
      if (this.discoveryTile.signed_media) {
        this.$refs.myVueDropzone.manuallyAddFile({ name: this.name, size: 100 }, this.discoveryTile.signed_media)
        this.$eventHub.$emit("fileIsUploading", false, this.bgId);
      }
    },
    registerEvents() {
      this.$eventHub.$on("commitAssets", (selectedAssets, adsetNum) => {
        if (this.adsetNum !== adsetNum) return

        const { preview_image } = selectedAssets
        if (!preview_image.media_key) return

        this.discoveryTile.media_key    = preview_image.media_key
        this.discoveryTile.signed_media = preview_image.signed_media
        this.discoveryTile.media_bucket = preview_image.media_bucket
        this.spreadDiscoveryTile(this.discoveryTile)
      })
    }
  },
  watch: {
    $data:{
      handler() {
        this.$eventHub.$emit("fieldChanged")
      },
      deep: true
    },
  },
  components: { vueDropzone }
};
</script>

<style>
</style>
