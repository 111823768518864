export default {
  methods: {
    makeRequest(payload){
      this.request(this.method, this.path, this.network, payload)
    },

    request(method, path, network, payload){
      if(network.request.isProcessing)
        return console.log('Previous request is still processing');

      network.response.status      = null;
      network.response.message     = '';
      network.request.isProcessing = true;

      return this.$http
        .request({ url: `${this.host}/${path}`, data: payload, method: method })
        .then(res => {
          network.response.data    = res.data;
          network.response.status  = 'success';
          return res.data
        })
        .catch(this.errorHandler)
        .finally(() => network.request.isProcessing = false)
    },

    errorHandler(exeption){
      let fullError = (exeption.response || {}).status === 500 ? exeption.response.data.error : exeption.message;
      console.log(exeption);
      console.log(fullError);
      this.network.response.status    = 'error';
      this.network.response.message   = fullError.split('\n')[0];
      this.network.response.exception = exeption
    },

    createNetworkObject(){
      return {
        request:  { isProcessing: null, payload: null },
        response: { status: null, message: '', exception: null, data: null }
      }
    }
  }
}
