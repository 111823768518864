<template>
  <vs-card class="d-flex">
    <div class="flex flex-row">
      <div class="flex flex-col mr-6">
        <slot name="activateAndPause" />
      </div>

      <div class="mr-6">
        <slot name="budget" />
      </div>

      <div class="mr-6">
        <slot name="bid" />
      </div>
    </div>

    <label>
      Assign to:
      <select v-model="selectedAssigneeId" class="form-control">
        <option v-for="assignee in assignees" :key="assignee.first_name + assignee.last_name" :value="assignee.id">{{assignee.first_name}} {{assignee.last_name}}</option>
      </select>
      <span v-if="processing"><b-spinner small></b-spinner></span>
    </label>

    <slot name="assign" />

    <span class="error">{{error.message}}</span>
  </vs-card>
</template>

<script>
  import { ERROR } from "../../utils/campaign_form.js";
  export default {
    props: {
      uniqName:     String,
      assignees:    Array,
      selectedIds:  Array
    },
    data() {
      return {
        error:              { message: '' },
        changed:            false,
        processing:         false,
        selectedAssigneeId: null
      }
    },
    mounted(){
    },
    methods: {
      save() {
        this.updateStatus()
          .finally(() => {
            this.processing = false;
            this.$emit('doneProcessing');
          })
      },
      reset(){
        this.selectedAssigneeId = null;
      },
      updateStatus(){
        this.error.message = '';
        if (!this.selectedAssigneeId) return;

        this.processing = true;
        return this.$http.post(`${this.host}/campaigns/update_assignee`, {
          assignee_id:  this.selectedAssigneeId,
          campaign_ids: this.selectedIds
        })
          .then(() => {
            this.reset()
          })
          .catch(ex => {
            ERROR.handle(ex, this.error)
          })
      }
    },
    watch: {
      selectedAssigneeId: {
        handler: function(val){
          this.$emit('childChanged', { name: this.uniqName, value: !!val });
        }
      }
    }
  }
</script>
