<template>
  <span>
    <select v-model="selectedPreset" class="preset-names" :disabled="selectedSlugs.length === 0">
      <option :value="id" v-for="id in presetsNames" :key="id">{{ id }}</option>
    </select>
    <input v-model="newName" v-if="selectedPreset === 'Create new'" type="text" class="ml-1" :disabled="selectedSlugs.length === 0" placeholder="Preset Name"/>
    <NetworkButton  class=          "ml-1"
                    ref=            "newButton"
                    :key=           "selectedPreset"
                    :path=          "`site_blacklist_presets/${selectedPreset === 'Create new' ? '' : selectedPreset + '/add_to_preset'}`"
                    variant=        "success"
                    @clicked=       "setPayload"
                    :disabled=      "selectedSlugs.length === 0 || (selectedPreset === 'Create new' ? !newName : false)"
                    @onSuccess=     "onNetButtSuccess"
                    :feedbackObject="feedbackObject"
    >{{selectedPreset === 'Create new' ? 'Create With Selected Sites' : 'Add Selected To Preset'}}
    </NetworkButton>
  </span>
</template>
<script>
  import NetworkRequest from './NetworkRequest.js'

  export default {
    name: 'SiteBlacklistPresets',
    mixins: [NetworkRequest],
    props: {
      selectedSlugs:  Array,
      feedbackObject: Object
    },
    data() {
      return {
        path:           'site_blacklist_presets/index/taboola',
        method:         'get',
        newName:        null,
        network:        this.createNetworkObject(),
        presetsNames:   [],
        selectedPreset: 'Create new'
      }
    },
    mounted() {
      this.makeRequest()
    },
    methods: {
      setPayload(networkRequest) {
        networkRequest.payload = { slugs: this.selectedSlugs, platform: 'taboola' };
        if (this.selectedPreset === 'Create new') networkRequest.payload.id = this.newName;
      },
      onNetButtSuccess(payload) {
        this.feedbackObject.text = `${payload.slugs.length} sites successfully added to: [ ${this.newName || this.selectedPreset} ]`;
        this.makeRequest();
      }
    },
    watch: {
      'network.response.data': function(data) {
        this.presetsNames   = ['Create new', ...data.map(d=>d._id)];
        this.selectedPreset = 'Create new'
      },
      'network.response.status': function(val) {
        if (!val) return;

        if(val !== 'success')
          this.feedbackObject.text = this.network.response.message;
        this.feedbackObject.class = val;
      }
    }
  }
</script>
<style scoped>
  .preset-names {
    min-width: 6rem
  }
</style>
