<template>
  <div class="m-2">
    <MultiEditAssignee :selectedIds="selectedIds" :assignees="assignees" @childChanged="childChanged($event)" @doneProcessing="doneProcessing($event)" :uniqName="'assignees'">
      <template v-slot:activateAndPause>
        <span class="text-center">Active</span>
        <b-button
          @click="onBulkStatusChange(selectedIds, 'running')"
          variant="success"
          size="sm"
          :disabled="selectedIds.length == 0"
          class="my-1"
          >Activate</b-button
        >
        <b-button
          @click="onBulkStatusChange(selectedIds, 'paused')"
          variant="success"
          size="sm"
          :disabled="selectedIds.length == 0"
          class="my-1"
          >Pause</b-button
        >
      </template>
      <template v-slot:budget>
        <span class="text-center">Budget</span>
        <div class="flex flex-row">
          <div class="flex flex-col">
            <b-button
              v-for="(p, key) of [5, 10, 15, 20]"
              :key="key"
              @click="onBudgetChange(selectedIds, p)"
              variant="success"
              size="sm"
              :disabled="selectedIds.length == 0"
              class="my-1 mr-2 w-12"
              >+{{ p }}%</b-button
            >
          </div>
          <div class="flex flex-col">
            <b-button
              v-for="(p, key) of [-10, -25, -50]"
              :key="key"
              @click="onBudgetChange(selectedIds, p)"
              variant="danger"
              size="sm"
              :disabled="selectedIds.length == 0"
              class="my-1 w-12"
              >{{ p }}%</b-button
            >
          </div>
      </div>
      </template>
      <template v-slot:bid>
        <span class="text-center">Bid</span>
        <div class="flex flex-row">
          <div class="flex flex-col">
            <b-button
              v-for="(p, key) of [5, 10, 15, 20]"
              :key="key"
              @click="onMaxBidChange(selectedIds, p)"
              variant="success"
              size="sm"
              :disabled="selectedIds.length == 0"
              class="my-1 mr-2 w-12"
              >+{{ p }}%</b-button
            >
          </div>
          <div class="flex flex-col">
            <b-button
              v-for="(p, key) of [-10, -20, -30]"
              :key="key"
              @click="onMaxBidChange(selectedIds, p)"
              variant="danger"
              size="sm"
              :disabled="selectedIds.length == 0"
              class="my-1 w-12"
              >{{ p }}%</b-button
            >
          </div>
      </div>
      </template>
      <template v-slot:assign>
        <b-button @click="save()" variant="success" :disabled="saveDisabled" size="sm" class="b-button m-4">Assign</b-button>
        <b-button @click="reset()" variant="light" :disabled="resetDisabled" size="sm" class="b-button">Reset</b-button>
      </template>
    </MultiEditAssignee>
  </div>
</template>

<script>
  import MultiEditAssignee  from "./MultiEditAssignee.vue";

  export default {
    components: { MultiEditAssignee },
    props: {
      assignees:    Array,
      selectedIds:  Array,
      selectedCampaigns:  Array,
      onBulkStatusChange: Function,
      onBudgetChange: Function,
      onMaxBidChange: Function,
    },
    data() {
      return {
        processing:         0,
        saveDisabled:       true,
        resetDisabled:      true,
        childrenChanges:    {},
        selectedIdsSnap:    '[]',
        childrenChanged:    false,
        selectedIdsChanged: false,
      }
    },
    mounted(){
    },
    methods: {
      snap() {
        this.selectedIdsSnap = JSON.stringify(Array.from(this.selectedIds).sort());
      },
      save() {
        this.processing = this.$children.length;
        this.$children.forEach(child => child.save());
      },
      childChanged(child) {
        this.childrenChanges[child.name] = child.value;
        this.childrenChanged = Object.values(this.childrenChanges).filter(v=>v).length > 0;
      },
      doneProcessing() {
        this.processing --;
      },
      reset() {
        this.selectedIdsSnap = '[]';
        this.selectedIds.splice(0,this.selectedIds.length);
        this.$children.forEach(child => child.reset());
      },
      buttonEval() {
        let prosessing      = this.processing > 0;
        this.saveDisabled   = prosessing || !(this.selectedIdsChanged && this.childrenChanged);
        this.resetDisabled  = prosessing || !(this.selectedIdsChanged || this.childrenChanged)
      }
    },
    watch: {
      selectedIds: {
        handler: function(val) {
          this.selectedIdsChanged = this.selectedIdsSnap !== JSON.stringify(Array.from(val).sort())
        }
      },
      processing:         { handler: function() { this.buttonEval() } },
      childrenChanged:    { handler: function() { this.buttonEval() } },
      selectedIdsChanged: { handler: function() { this.buttonEval() } }
    },
  }
</script>
<style scoped>
  .b-button {
    margin-right: 10px;
  }

  /deep/ .error {
    color: red;
    font-weight: bold;
    padding-left: 5px
  }
  /deep/ .select {
    margin-left: 10px;
  }
</style>
