<template>
  <div id="campaign-form">
    <div v-if="adAccounts.length == 0" class="d-flex justify-content-center m-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <form v-else autocomplete="off" @submit.prevent="submit">
      <vs-card>
        <div slot="header">
          <h4>Details</h4>
        </div>
        <div class="form-group row justify-content-center">
          <!--update disabled-->
          <label for="name" class="text-right col-sm-1 col-form-label">UTM Campaign Name
            <p v-if="name.length >= 300" class="bold-red mb-0">
              ({{ name.length }}/300)
            </p>
          </label>
          <div class="col-sm-9">
            <textarea class="form-control" disabled id="name" v-model="name" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update enabled via name generator-->
          <label for="display_name" class="text-right col-sm-1 col-form-label">Display Name</label>
          <div class="col-sm-9">
            <textarea class="form-control" disabled id="display_name" v-model="display_name" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update enabled-->
          <label for="name" class="text-right col-sm-1 col-form-label">Title</label>
          <div class="col-sm-9">
            <input class="form-control" id="title" v-model="title" placeholder="Campaign Title"
              v-on:keypress="isAlphaNumeric($event, 'title')" @change="checkName(title, 'title')" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update enabled-->
          <label for="custom_name" class="text-right col-sm-1 col-form-label">Custom Name</label>
          <div class="col-sm-9">
            <input class="form-control" name="t-custom_name" id="custom_name" v-model="custom_name"
              v-on:keypress="isAlphaNumeric($event, 'custom_name')" @change="checkName(custom_name, 'custom_name')" />
          </div>
        </div>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h4>Source & Settings</h4>
        </div>
        <div class="form-group row justify-content-center">
          <!--update disabled-->
          <label for="platform" class="text-right col-sm-1 col-form-label">Platform</label>
          <div class="col-sm-9 d-flex align-items-center">
            <div @click="selectOption('platform', 'facebook')" class="platform-button" :style="`background-color: ${platform == 'facebook'
            ? getPlatformColor('facebook')
            : '#ffffff'
            }`">
              <img v-show="platform === 'facebook'" src="@/assets/images/icons/icon_white_facebook.svg" />
              <img v-show="platform !== 'facebook'" src="@/assets/images/icons/icon_colored_facebook.svg" />
            </div>
            <div @click="selectOption('platform', 'taboola')" class="platform-button" :style="`background-color: ${platform == 'taboola' ? getPlatformColor('taboola') : '#ffffff'
            }`">
              <img v-show="platform === 'taboola'" src="@/assets/images/icons/icon_white_taboola.svg" />
              <img v-show="platform !== 'taboola'" src="@/assets/images/icons/icon_colored_taboola.svg" />
            </div>
            <div @click="selectOption('platform', 'pinterest')" class="platform-button" :style="`background-color: ${platform == 'pinterest'
            ? getPlatformColor('pinterest')
            : '#ffffff'
            }`">
              <img v-show="platform === 'pinterest'" src="@/assets/images/icons/icon_white_pinterest.svg" />
              <img v-show="platform !== 'pinterest'" src="@/assets/images/icons/icon_colored_pinterest.svg" />
            </div>
            <div @click="selectOption('platform', 'snapchat')" class="platform-button" :style="`background-color: ${platform == 'snapchat'
            ? getPlatformColor('snapchat')
            : '#ffffff'
            }`">
              <img v-show="platform === 'snapchat'" src="@/assets/images/icons/icon_white_snapchat.svg" />
              <img v-show="platform !== 'snapchat'" src="@/assets/images/icons/icon_colored_snapchat.svg" />
            </div>
            <div @click="selectOption('platform', 'twitter')" class="platform-button" :style="`background-color: ${platform == 'twitter' ? getPlatformColor('twitter') : '#ffffff'
            }`">
              <img v-show="platform === 'twitter'" src="@/assets/images/icons/icon_white_twitter.svg" />
              <img v-show="platform !== 'twitter'" src="@/assets/images/icons/icon_colored_twitter.svg" />
            </div>
            <div @click="selectOption('platform', 'tiktok')" class="platform-button" :style="`background-color: ${platform == 'tiktok' ? getPlatformColor('tiktok') : '#ffffff'
            }`">
              <img v-show="platform === 'tiktok'" src="@/assets/images/icons/icon_white_tiktok.svg" />
              <img v-show="platform !== 'tiktok'" src="@/assets/images/icons/icon_colored_tiktok.svg" />
            </div>
            <div @click="selectOption('platform', 'yahoo')" class="platform-button" :style="`background-color: ${platform == 'yahoo' ? getPlatformColor('yahoo') : '#ffffff'
            }`">
              <img v-show="platform === 'yahoo'" src="@/assets/images/icons/icon_white_yahoo.svg" />
              <img v-show="platform !== 'yahoo'" src="@/assets/images/icons/icon_colored_yahoo.svg" />
            </div>
          </div>
        </div>
        <div v-if="showAdFormat" class="form-group row justify-content-center">
          <label for="ad_format" class="text-right col-sm-1 col-form-label">Ad Format</label>
          <div class="col-sm-9">
            <div class="toggles">
              <div class="toggle-button" :class="selectedSnapAdFormat == opt.value ? 'active' : ''" :key="opt.text"
                v-for="opt in snapAdOptions" @click="selectOption('selectedSnapAdFormat', opt.value)">
                {{ opt.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update disabled-->
          <label for="publisher" class="text-right col-sm-1 col-form-label">Publisher</label>
          <div class="col-sm-9">
            <select id="publisher" :disabled="isFieldDisabled('publisher')" required v-model="publisher"
              class="form-control">
              <option :key="opt.name" :value="opt.id" v-for="opt in publishers">
                {{ opt.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update disabled-->
          <label for="adAccounts" class="text-right col-sm-1 col-form-label">Ad Account</label>
          <div class="col-sm-9">
            <select :disabled="isFieldDisabled('adAccount')" v-model="adAccount" required id="adAccounts"
              class="form-control">
              <option :key="acc.name" :value="acc.id" v-for="acc in computedAdAccounts">
                {{ acc.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row justify-content-center align-items-center"
          v-if="!dry && platform_options.objectives">
          <!--update disabled-->
          <label for="objective" class="text-right col-sm-1 col-form-label">Objective</label>
          <div class="col-sm-9">
            <div class="toggles" :disabled="isFieldDisabled('objective')">
              <div class="toggle-button" :class="objective == opt ? 'active' : ''" :key="opt"
                v-for="opt in platform_options.objectives" @click="selectOption('objective', opt)">
                {{ opt }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row justify-content-center" v-if="platform_options.boards">
          <!--update disabled-->
          <label for="objective" class="text-right col-sm-1 col-form-label">Board</label>
          <div class="col-sm-9">
            <select v-model="selectedBoardId" id="board-id" class="form-control">
              <option :key="board.external_id" :value="board.external_id"
                v-for="board in platform_options.boards[publisher]">
                {{ board.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update enabled-->
          <label for="url" class="text-right col-sm-1 col-form-label">URL</label>
          <div class="col-sm-9">
            <input class="form-control" type="url" id="url" required v-model="url" placeholder="URL Address"
              @change="validateURL" />
          </div>
        </div>
        <div class="form-group row justify-content-center" v-if="!isUrlValid">
          <div class="col-sm-1"></div>
          <div class="col-sm-9 url-validation-sign">
            <span>{{ urlStatus }}</span>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <!--update enabled via url-->
          <label for="utm_medium" class="text-right col-sm-1 col-form-label">Article ID</label>
          <div class="col-sm-9">
            <input class="form-control" id="utm_medium" v-model="utm_medium" placeholder="Campaign Medium" required
              pattern="^[0-9]+$" />
          </div>
        </div>
        <div v-if="(platform_options.daily_budget || {}).campaign" class="form-group row justify-content-center">
          <!--update disabled-->
          <label for="name" class="text-right col-sm-1 col-form-label">Campaign Budget</label>
          <div class="col-sm-9">
            <div class="input-group">
              <div class="col-sm-2">
                <select :disabled="isFieldDisabled('adAccount')" v-model="budget_type" id="budget_type"
                  class="form-control">
                  <option :key="budget.text" :value="budget.value" v-for="budget in budget_types">
                    {{ budget.text }}
                  </option>
                </select>
              </div>
              <div class="input-group-prepend">
                <div class="input-group-text">USD</div>
              </div>
              <input class="form-control" id="daily_budget_camp" :disabled="isFieldDisabled('campaign_budget')"
                type="text" v-model="daily_budget" />
            </div>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-sm-1"></div>
          <!--update disabled-->
          <div class="col-sm-2 d-flex align-items-center">
            <b-form-checkbox switch :disabled="platform != 'taboola'" size="lg" v-model="split" id="split" />
            <label class="form-check-label" for="split" style="margin: 0">Split Campaign</label>
          </div>
          <div class="col-sm-7 align-items-center">
            <div v-if="!id" class="form-check form-check-inline">
              <b-form-checkbox switch size="lg" v-model="dry" id="dry" />
              <label class="form-check-label" for="dry">Skip Campaign Creation On Platform</label>
            </div>
          </div>
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h4>AB Testing</h4>
        </div>
        <ABTestPicker ref="ab-tests" :campaign="campaign" :platform="platform" :editMode="editMode"
          :duplicateMode="duplicateMode" />
      </vs-card>

      <div v-if="!dry && optionsDoneLoading">
        <Adsets :isFieldDisabled="isFieldDisabled" :platform="platform" :adAccount="adAccount" :objective="objective"
          :loadedData="adsets" :adGUrlData="adGUrlData" :isSnapStory="isSnapStory" :customParams="customParams"
          :campaignName="name" :adsetAudiences="adsetAudiences" :conversionLists="conversionLists"
          :platform_options="platform_options" :initialAdsetCount="initialAdsetCount"
          :creativeLibraryFilters="creativeLibraryFilters" :duplicateMode="duplicateMode" />
      </div>
      <vs-button v-if="!isFieldDisabled('submit')" :disabled="inProgress || filesQueue.length > 0" color="primary"
        class="submit-button m-2" @click="validateAndSubmitForm">{{ submitButtonText }}
      </vs-button>
    </form>
  </div>
</template>

<script>
import Adsets from "./Adsets.vue";
import ABTestPicker from "./ABTestPicker.vue";
import {
  UTILS,
  VALIDATION,
  DEFAULT_VALS,
  OPTIONS,
  NAME_GENERATORS,
} from "../../utils/campaign_form.js";
import {
  UTM_SOURCES,
  PUBLISHERS,
  PLATFORMS,
} from "../../utils/product_entities.js";
import { ilog } from "../../utils/logger/logger.js";
import { SNAP_AD_OPTIONS, BUDGET_TYPES } from "../../utils/values";
import platformOptions from "../../utils/platform_options";

export default {
  name: "CampaignForm",
  props: {
    abTests: Object,
    loadedData: Object,
    submit: Function,
    setName: Function,
    name: String,
    creator: Object,
    uniq_num: String,
    campaign: Object,
    inProgress: Boolean,
    editMode: Boolean,
    duplicateMode: Boolean,
    disabledFieldsByPlatform: Function,
    resetOriginalValues: Function,
  },
  data: function () {
    return {
      selectedSnapAdFormat: "single",
      snapAdOptions: SNAP_AD_OPTIONS,
      platforms: PLATFORMS,
      publishers: PUBLISHERS,
      time: null,
      dry: false,
      id: null,
      split: false,
      optionsDoneLoading: false,
      platform_options: {},
      selectedBoardId: null,
      conversionLists: {},
      title: "",
      display_name: "",
      custom_name: "",
      utm_medium: null,
      publisher: null,
      platform: "",
      utm_source: "",
      url: null,
      adAccount: null,
      adAccounts: [],
      objective: null,
      options: null,
      daily_budget: null,
      budget_type: 'daily',
      budget_types: BUDGET_TYPES,
      adsets: null,
      initialAdsetCount: 0,
      filesQueue: [],
      adsetAudiences: [],
      customParams: { isPurchase: false },
      isUrlValid: true,
      urlStatus: "",
      platformOptions: platformOptions,
    };
  },
  mounted() {
    this.time = parseInt(Date.now() / 1000);
    this.loadAdAccounts()
      .then(() => this.spreadCampaign(this.campaign))
      .then(() => {
        ilog("Options done loading");
        this.optionsDoneLoading = true;
      })
      .then(() => {
        if (this.id) this.resetOriginalValues();
      });

    this.$eventHub.$on("fileIsUploading", (uploading, bindingKey) => {
      uploading
        ? this.filesQueue.push(bindingKey)
        : (this.filesQueue = this.filesQueue.filter((f) => f != bindingKey));
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("fileIsUploading");
  },
  watch: {
    $data: {
      handler() {
        if (!this.optionsDoneLoading || !this.platform || !this.adAccount)
          return;
        this.display_name = NAME_GENERATORS.campaign(this);
        this.setName(this.display_name);
        this.$eventHub.$emit("fieldChanged");
      },
      deep: true,
    },
    platform: function (newVal, oldVal) {
      if (newVal !== undefined)
        this.platform_options = OPTIONS.platform(newVal) || {};
      this.utm_source = UTM_SOURCES[newVal];

      if (!this.id || oldVal) {
        this.daily_budget = DEFAULT_VALS.get(newVal, "campaign_budget");
      }
      if ((this.platform_options.objectives || {}).length === 1)
        this.objective = this.platform_options.objectives[0];
      UTILS.setDefaults(this, "campaign");
    },
    publisher: function () {
      if (!this.url && window.location.href.match(/\.local/)) {
        this.url = this.selectedPublisher.url;
        this.url += "/" + this.time;
      }

      this.validateURL();
    },
  },
  computed: {
    showAdFormat() {
      return !this.dry && this.platform === "snapchat";
    },
    isSnapStory() {
      return this.showAdFormat && this.selectedSnapAdFormat === "story";
    },
    adGUrlData() {
      return {
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
        url: this.url,
        platform: this.platform,
      };
    },
    creativeLibraryFilters() {
      return {
        article_id: this.utm_medium,
        publisher_id: this.publisher,
        platform: this.platform,
      };
    },
    computedAdAccounts: function () {
      return this.adAccounts.filter((ad) => {
        return (
          ad.platform === this.platform && ad.publisher_id === this.publisher
        );
      });
    },
    submitButtonText() {
      if (this.filesQueue.length > 0) return "Uploading files...";
      if (this.inProgress) return "Creating...";
      return "Create Campaign";
    },
    selectedPublisher() {
      return PUBLISHERS.find((p) => p.id === this.publisher);
    },
  },
  methods: {
    getPlatformColor(platform) {
      return this.platformOptions[platform].color;
    },
    selectOption(field, value) {
      this[field] = value;
    },
    isFieldDisabled(field, id = null) {
      return this.disabledFieldsByPlatform(field, this.platform, id);
    },
    validateURL() {
      if (!this.url) return;

      this.url = this.url.trim();
      this.url = this.url.split("?")[0];

      if (
        !this.selectedPublisher ||
        this.url.indexOf(this.selectedPublisher.url) !== 0
      ) {
        document
          .getElementById("url")
          .setCustomValidity("URL does not comply with the prefix");
      } else document.getElementById("url").setCustomValidity("");
      this.$http
        .get(`${this.host}/campaigns/check_url_status?url=${this.url}`, {
          timeout: 10000,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isUrlValid = true;
          } else if (res.data.code == 301 || res.data.code == 302) {
            this.isUrlValid = false;
            this.url = res.data.redirect_url;
            this.urlStatus = "URL updated to redirect";
          } else {
            document
              .getElementById("url")
              .setCustomValidity("URL doesn't exist");
            this.urlStatus = "URL doesn't exist";
            this.isUrlValid = false;
          }
        });
    },
    checkName: function (model, element) {
      if (model === "") return;

      let regExp = element.includes("adName")
        ? /^[a-z0-9\-_]+$/i
        : /^[a-z0-9\-_ ]+$/i;
      if (model.match(regExp) === null)
        document
          .getElementById(element)
          .setCustomValidity("You have non alpha numeric values");
      else document.getElementById(element).setCustomValidity("");
    },
    isAlphaNumeric: function (evt, type) {
      let regExp = type.includes("adName")
        ? /^[a-z0-9\-_]+$/i
        : /^[a-z0-9\-_ ]+$/i;
      let charCode = evt.charCode;
      if (String.fromCharCode(charCode).match(regExp) !== null) return true;
      else evt.preventDefault();
    },
    async loadAdAccounts() {
      let res = await OPTIONS.provided(this.$http, this.host);
      this.adAccounts = res.ad_accounts;
      this.conversionLists = res.fb_conversion_lists;
    },
    async spreadCampaign(campaign) {
      Object.keys(campaign).forEach((key) => {
        this[key] = campaign[key];
      });
    },
    metadataObj() {
      return this.showAdFormat
        ? { ad_format: this.selectedSnapAdFormat }
        : null;
    },
    toObj() {
      const adsets = this.dry
        ? []
        : this.$children.find((c) => c.$options.name === "Adsets").toObj();
      adsets.forEach((adset) => {
        adset.creatives.forEach((creative) => {
          creative.external_board_id = this.selectedBoardId;
        });
      });
      const daily_budget = (this.platform_options.daily_budget || {}).campaign
        ? this.daily_budget
        : null; //clean old campaigns
      const ab_tests = this.$refs["ab-tests"].toObj();

      return UTILS.clone({
        platform: this.platform,
        dry: this.dry,
        ad_account_id: this.adAccount,
        campaigns: [
          {
            id: this.id,
            name: this.name,
            uniq_num: this.uniq_num,
            display_name: this.display_name,
            daily_budget: daily_budget,
            budget_type: this.budget_type,
            title: this.title,
            custom_name: this.custom_name,
            utm_medium: this.utm_medium,
            objective: this.objective,
            external_board_id: this.selectedBoardId,
            platform: this.platform,
            split: this.split,
            metadata: this.metadataObj(),
            adsets,
            ab_tests,
          },
        ],
      });
    },
    validateForm() {
      if (!document.forms[0].reportValidity()) return false;

      let formObject = this.toObj();
      let campaign = formObject.campaigns[0];
      const { adsets, ab_tests } = formObject.campaigns[0];

      if (!VALIDATION.creatives(adsets)) return false;
      if (!VALIDATION.countries(adsets) && !this.dry) return false;
      if (!VALIDATION.locales(adsets, this.platform) && !this.dry) return false;
      if (!VALIDATION.abTesting(ab_tests)) return false;

      if (this.isSnapStory) {
        if (!VALIDATION.snapStory(adsets)) return false;
      }
      if (!this.isFieldDisabled("utm_campaign")) {
        if (!VALIDATION.utm_campaign(this.name)) return false;
      }
      if (!this.isFieldDisabled("max_bid")) {
        if (!VALIDATION.bid(adsets)) return false;
      }

      if (!VALIDATION.platform(campaign)) return false;

      return formObject;
    },
    validateAndSubmitForm() {
      let formObject = this.validateForm();
      if (formObject) this.submit(formObject);
    },
  },
  components: {
    Adsets,
    ABTestPicker,
  },
};
</script>
<style lang="scss">
#campaign-form {
  .con-vs-card {
    display: flex !important;
    box-shadow: 0 0 0 !important;
  }

  .vs-card--header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 150px;
  }

  .vs-card--content {
    box-shadow: 4px -6px 10px #0000002b;
    border-radius: 19px;
    width: 100%;
  }

  input,
  textarea,
  select {
    background-color: #f0f0f0 !important;
    border-radius: 10px;
  }

  .form-group #display_name,
  .form-group #name {
    border: 2px solid #727272;
  }

  input,
  select {
    border: 0px;
  }

  .input-group-text {
    height: 100% !important;
    width: 60px;
    border-radius: 19px !important;
    display: flex !important;
    justify-content: center;
    color: #909090 !important;
    background-color: #f0f0f0 !important;
  }

  #daily_budget_camp {
    margin-left: 8px;
  }

  .text-right {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .toggles {
    display: flex;

    .toggle-button {
      margin-right: 8px;
      margin-right: 8px;
      border: 1px solid #4bceac;
      padding: 0.3rem 0.7rem;
      border-radius: 19px;
      color: #4bceac;
      cursor: pointer;

      &.active {
        color: white;
        border-color: white;
        background-color: #4bceac;
      }
    }
  }

  .submit-button {
    margin: auto !important;
    display: block;
    border-radius: 19px;
  }

  .custom-control.custom-switch.b-custom-control-lg {
    margin-top: -6px;
  }
}
</style>
<style lang="scss">
.platform-button {
  height: 25px;
  width: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 80%;
  }
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-size: 2rem;
}

.url-validation-sign {
  color: red;
  margin-top: -15px;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #ffffff;
}

.dz {
  margin-bottom: 1rem;
}

.creative-size {
  font-weight: bold;
}

.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}

.alert-danger {
  white-space: pre-line;
}

.submit-hr {
  margin: 3rem;
}

button.update-btn {
  line-height: 0.8rem;
  padding: 0.3rem 0.2rem;
  font-size: inherit;
  margin-right: 1rem;
}

div div.creatives-update-container {
  display: flex;
  justify-content: center;
}

span.gurl {
  word-break: break-word;
}

.move-left {
  width: auto;
  box-shadow: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
