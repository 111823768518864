<template>
  <div>
    <div
      class="con-vs-card"
      :key="'adset-' + adset.bindingKey"
      v-for="adset in adsets"
    >
      <Adset
        :bindingKey="adset.bindingKey"
        :goals="goals"
        :isFieldDisabled="isFieldDisabled"
        :conversionList="conversionList"
        :platform="platform"
        :isBidRequired="isBidRequired"
        :serialNum="adset.serialNum"
        :removeAdset="removeAdset"
        :adGUrlData="adGUrlData"
        :adset="adset.loadedData"
        :adsetAudiences="adsetAudiences"
        :platform_options="platform_options"
        :campaignName="campaignName"
        :isCustomConversion="isCustomConversion"
        :creativeLibraryFilters="creativeLibraryFilters"
        :isSnapStory="isSnapStory"
        :customParams="customParams"
        :duplicateMode="duplicateMode"
      />
    </div>
    <vs-button
      :disabled="!platform_options.supports_multi_adsets"
      color="success"
      class="m-2"
      icon="add"
      @click="duplicateLastAdset"
      >Add Adset</vs-button
    >
  </div>
</template>
<script>
import Adset from "./Adset.vue";
import {
  UTILS,
  CUSTOM_CONVERSION,
  DEFAULT_VALS,
} from "../../utils/campaign_form.js";
import { alog } from "../../utils/logger/logger.js";

export default {
  name: "Adsets",
  props: {
    campaignName: String,
    platform_options: Object,
    adGUrlData: Object,
    platform: String,
    objective: String,
    adAccount: Number,
    isFieldDisabled: Function,
    loadedData: Array,
    adsetAudiences: Array,
    conversionLists: Object,
    initialAdsetCount: Number,
    creativeLibraryFilters: Object,
    isSnapStory: Boolean,
    customParams: Object,
    duplicateMode: Boolean,
  },
  data: function () {
    return {
      adsets: [],
      adsetCounter: 0,
      isBidRequired: true,
      conversionList: {},
    };
  },
  mounted() {
    if (this.loadedData) {
      this.spreadAdsets();
    } else this.addFirstAdset();
  },
  watch: {
    objective(newVal) {
      this.isBidRequired = newVal !== "OUTCOME_LEADS";
    },
  },
  computed: {
    isCustomConversion: function () {
      let isCC = CUSTOM_CONVERSION.isSupported(
        this.objective,
        this.platform,
        this.adAccount
      );
      // TODO: Fix this
      // eslint-disable-next-line
      this.conversionList = isCC ? this.conversionLists[this.adAccount] : {};
      return isCC;
    },
    goals: function () {
      if (!this.platform_options.goals) return;

      return this.platform_options.goals[
        this.isSnapStory ? "STORY" : this.objective
      ];
    },
  },
  methods: {
    spreadAdsets() {
      this.adsetCounter = this.initialAdsetCount;
      this.adsets = this.loadedData.sort((a, b) =>
        a.serialNum > b.serialNum ? 1 : -1
      );
      this.adsets.forEach((adset) => {
        adset.bindingKey = UTILS.bindingKey();
      });
    },
    addFirstAdset() {
      const max_bid = DEFAULT_VALS.get(this.platform, "bid");
      const age_min = DEFAULT_VALS.get(this.platform, "age_min");
      const locales = DEFAULT_VALS.get(this.platform, "locales");
      const browsers = DEFAULT_VALS.get(this.platform, "browsers");
      const daily_budget = DEFAULT_VALS.get(this.platform, "adset_budget");
      const operating_systems = DEFAULT_VALS.get(
        this.platform,
        "operating_systems"
      );

      const defaults = {
        daily_budget,
        max_bid,
        age_min,
        locales,
        browsers,
        operating_systems,
      };
      const adset = {
        bindingKey: UTILS.bindingKey(),
        serialNum: ++this.adsetCounter,
        loadedData: defaults,
      };

      this.adsets.push(adset);
      alog("add first adset", `adset ${adset.serialNum}`, adset);
    },
    duplicateLastAdset() {
      let prevAdsetClone = this.$children
        .reverse()
        .find((c) => c.$options.name === "Adset")
        .toObj();
      const prevLogCopy = UTILS.clone(prevAdsetClone);

      delete prevAdsetClone.id;
      delete prevAdsetClone.external_id;
      delete prevAdsetClone.serialNum;
      delete prevAdsetClone.initialAdCount;

      let creativesCounter = 0;
      prevAdsetClone.creatives.forEach((cre) => {
        delete cre.id;
        delete cre.external_id;
        delete cre.adset_id;

        cre.serialNum = ++creativesCounter;
      });

      const newAdset = {
        bindingKey: UTILS.bindingKey(),
        serialNum: ++this.adsetCounter,
        loadedData: prevAdsetClone,
      };

      this.adsets.push(newAdset);
      alog(`add adset`, prevLogCopy, newAdset);
    },
    removeAdset(serialNum) {
      const adsetToRemove = this.adsets.find((a) => serialNum == a.serialNum);
      const { id } = adsetToRemove.loadedData;
      this.adsets = this.adsets.filter((a) => serialNum !== a.serialNum);

      if (!id) {
        this.adsets.forEach((other) => {
          if (other.serialNum > serialNum) {
            other.serialNum--;
          }
        });
        --this.adsetCounter;
      }
      alog("remove adset", `adset ${id ? id : serialNum}`, adsetToRemove);
    },
    toObj() {
      const adsets = this.$children
        .filter((c) => c.$options.name == "Adset")
        .map((adset) => adset.toObj());
      return UTILS.clone(adsets);
    },
  },
  components: {
    Adset,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
