<template>
  <span class="form-button">
    <b-button
      class="b-button"
      @click="getPayload"
      :variant="variant || 'danger'"
      size="sm"
      :disabled="disabled || network.request.isProcessing"
    >
      <i v-if="showPending && network.request.isProcessing" class="fas fa-sync fa-spin"></i>
      <slot v-else>Save</slot>
    </b-button>
  </span>
</template>

<script>
  import NetworkRequest from './NetworkRequest.js'

  export default {
    name:   'NetworkButton',
    mixins: [NetworkRequest],
    props: {
      path:           String,
      method:         { type: String, default: 'post' },
      payload:        Object,
      variant:        String,
      disabled:       { type: Boolean, default: true },
      showPending:    Boolean,
      feedbackObject: Object
    },
    data(){
      return {
        network: this.createNetworkObject(),
      }
    },
    methods: {
      getPayload(){
        if (this.payload)
          this.network.request.payload = this.payload.payload || this.payload;
        else if (this.method.match(/DELETE|GET/))
          this.makeRequest();
        else
          this.$emit('clicked', this.network.request)
      }
    },
    watch: {
      'network.request.payload': function(val){
        if(val) this.makeRequest(val)
      },
      'network.response.status': function(val){
        if (!val) return;

        if(val === 'success')
          this.$emit('onSuccess', this.network.request.payload);
        else
          this.feedbackObject.text = this.network.response.message;

        this.feedbackObject.class     = val;
        this.network.request.payload  = null;
        this.feedbackObject.timestamp = Date.now();
      },
      'network.response.data': function(val){
        if (!val) return;

        this.$emit('onResponseData', val);
      }
    }
  }
</script>