<template>
  <div>
    <div slot="header">
      <h4>Creatives</h4>
    </div>
    <div
      class="form-group row justify-content-center"
      :key="`creatives-${cre.bindingKey}`"
      v-for="cre in creatives"
    >
      <Creative
        :bindingKey="cre.bindingKey"
        :isFieldDisabled="isFieldDisabled"
        :serialNum="cre.serialNum"
        :moveCreative="moveCreative"
        :removeCreative="removeCreative"
        :adGUrlData="adGUrlData"
        :campaignName="campaignName"
        :creative="cre.loadedData"
        :platform_options="platform_options"
        :adsetNum="adsetNum"
        :creativeLibraryFilters="creativeLibraryFilters"
        :assets="assets"
        :forceDefaults="cre.forceDefaults"
        :isSnapStory="isSnapStory"
        :creativeCounter="creativeCounter"
        :duplicateMode="duplicateMode"
      ></Creative>
    </div>
    <b-modal
      id="AssetsModal"
      :ref="`library-modal`"
      centered
      ok-only
      title="Creatives Library"
      @ok="retrieveSelectedAssets"
    >
      <Library
        :miniView="true"
        :formFilters="creativeLibraryFilters"
        :selectedAssets="selectedAssets"
        :commitedAssets="assets"
        :adsetNum="adsetNum"
      />
    </b-modal>
    <div class="flex">
      <vs-button
        @click="toggleLibraryModal"
        :disabled="Object.keys(this.platform_options) === 0"
        color="success"
        icon="library_add"
        class="m-2"
        >Creatives Library</vs-button
      >
      <vs-button
        @click="addCreative"
        :disabled="Object.keys(this.platform_options) === 0"
        color="success"
        icon="add"
        class="m-2"
        >Add Creative</vs-button
      >
    </div>
  </div>
</template>
<script>
import Creative from "./Creative.vue";
import Library from "../../views/Creatives/Library/Library.vue";
import { UTILS } from "../../utils/campaign_form.js";
import { alog } from "../../utils/logger/logger.js";

export default {
  name: "Creatives",
  props: {
    creativeLibraryFilters: Object,
    adGUrlData: Object,
    campaignName: String,
    adsetNum: Number,
    platform_options: Object,
    loadedData: Array,
    initialAdCount: Number,
    isFieldDisabled: Function,
    isSnapStory: Boolean,
    duplicateMode: Boolean,
  },
  data: function() {
    return {
      creatives: [],
      creativeCounter: 0,
      assets: [],
      selectedAssets: { text: [], preview_image: [], creative_image: [] }
    };
  },
  mounted() {
    if (this.initialAdCount) this.creativeCounter = this.initialAdCount;
    this.spreadCreatives(this.loadedData);

    this.registerEvents();
  },
  beforeDestroy() {
    this.$eventHub.$off("commitAsset");
    this.$eventHub.$off("uncommitAsset");
  },
  methods: {
    registerEvents() {
      this.$eventHub.$on("commitAssets", (selectedAssets, adsetNum) => {
        if (this.adsetNum !== adsetNum) return

        this.assets.push(selectedAssets);
        this.selectedAssets = { text: [], preview_image: [], creative_image: [] };
        const { creative_image, text } = selectedAssets;
        creative_image.forEach(creative => {
          const newCreativeData = {
            id: `${text.id}-${creative.id}`,
            headline: text.headline,
            text: text.caption,
            media_key: creative.media_key,
            media_bucket: creative.media_bucket,
            signed_media: creative.signed_media
          };
          const newCreative = {
            bindingKey: UTILS.bindingKey(),
            serialNum: ++this.creativeCounter,
            loadedData: newCreativeData,
            forceDefaults: true
          };
          this.creatives.push(newCreative);
        });
      });
      this.$eventHub.$on("uncommitAsset", i => {
        const removedAsset = this.assets.splice(i, 1)[0];
        const idsToRemove = removedAsset.creative_image.map(
          creative => `${removedAsset.creative_image.id}-${creative.id}`
        );
        this.creatives = this.creatives.filter(
          c => !idsToRemove.includes(c.loadedData.id)
        );
        this.creativeCounter = this.creatives.length;
      });
    },
    moveCreative(from, to) {
      this.creatives.splice(to, 0, this.creatives.splice(from, 1)[0]);
      this.creatives.forEach((creative, index) => {
        creative.serialNum = index + 1;
      });
    },
    retrieveSelectedAssets(bvModalEvt) {
      if (
        (this.selectedAssets.text.length === 0 && this.selectedAssets.creative_image.length === 0) ||
        window.confirm("You have unsaved assets, are you sure you want to continue without saving?")
      ) {
        this.selectedAssets = { text: [], preview_image: [], creative_image: [] }
      } else {
        bvModalEvt.preventDefault();
      }
    },
    toggleLibraryModal() {
      this.$refs[`library-modal`].toggle(`#modal-btn`);
    },
    async spreadCreatives(data) {
      if (!data) return;

      this.creatives = data
        .map(cre => {
          let num = cre.serialNum;
          delete cre.serialNum;
          delete cre.bindingKey;

          if (this.creativeCounter < num) this.creativeCounter = num;

          return {
            bindingKey: UTILS.bindingKey(),
            serialNum: num,
            loadedData: cre
          };
        })
        .sort((a, b) => (a.serialNum > b.serialNum ? 1 : -1));
    },
    addCreative() {
      if (Object.keys(this.platform_options) == 0) {
        alert("must select platform before adding creatives");
        return;
      }

      const last = this.$children
        .reverse()
        .find(c => c.$options.name == "Creative");
      let defaults = {},
        logCopy = {};

      if (last) {
        defaults = last.toObj();
        logCopy = UTILS.clone(defaults);
        delete defaults.serialNum;
        delete defaults.media_key;
        delete defaults.signed_media;
        delete defaults.id;
        delete defaults.external_id;
        delete defaults.bindingKey;
      }

      const newCreative = {
        bindingKey: UTILS.bindingKey(),
        serialNum: ++this.creativeCounter,
        loadedData: defaults
      };

      this.creatives.push(newCreative);
      alog(`add creative ${newCreative.serialNum}`, logCopy, newCreative);
    },
    removeCreative(serialNum) {
      const creToRemove = this.creatives.find(cre => serialNum === cre.serialNum);

      this.assets.forEach(asset => {
        asset.creative_image.forEach(creative_image => {
          if (creToRemove.loadedData.id === `${asset.text.id}-${creative_image.id}`) {
            if (asset.creative_image.length > 1) {
              const i = asset.creative_image.findIndex(m => m.id === creative_image.id);
              asset.creative_image.splice(i, 1);
            } else {
              const i = this.assets.findIndex(a => a.text.id === asset.text.id && a.creative_image.id === asset.creative_image.id);
              this.assets.splice(i, 1);
            }
          }
        });
      });

      const { id } = creToRemove.loadedData;
      alog("remove creative", `creative ${id ? id : serialNum}`, creToRemove);

      this.creatives = this.creatives.filter(
        cre => serialNum !== cre.serialNum
      );

      //Edgecase: while editing. adding 2 new ads, the deleting the first, then saving. counter on ads created will be incremented by 1 while the new ad will be named "ad_2". in the next edit, new ad will also be named "ad_2"
      this.creatives.forEach(other => {
        if (other.serialNum > serialNum) {
          other.serialNum--;
        }
      });
      --this.creativeCounter;
    },
    toObj() {
      const creatives = this.$children
        .filter(c => c.$options.name == "Creative")
        .map(cre => cre.toObj());

      return UTILS.clone(creatives);
    }
  },
  components: {
    Creative,
    Library
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
