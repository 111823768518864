<template>
  <div>
    <div>
      <form ref="form" v-on:change="validateChange" @submit.prevent="onSubmit">
        <slot/>
        <NetworkButton
          v-if            = "!hideSubmit"
          :path           = "path"
          :method         = "method"
          @clicked        = "setPayload"
          :disabled       = "submitDisabled"
          @onSuccess      = "onSuccess"
          :showPending    = "showPending || false"
          :feedbackObject ="feedbackObject"
        >
          {{submitLabel || 'Save'}}
        </NetworkButton>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormSubmitter',
    props: {
      path:           String,
      method:         String,
      enabled:        { type: Boolean, default: false },
      disabled:       { type: Boolean, default: true },
      hideSubmit:     Boolean,
      showPending:    Boolean,
      submitLabel:    String,
      feedbackObject: Object
    },
    computed: {
      submitDisabled: function() {
        if (this.enabled) return false

        return (this.disabled || !this.changeDetected)
      }
    },
    data() {
      return {
        formSnapshot:   '',
        changeDetected: false
      }
    },
    methods: {
      setPayload(networkRequest){
        this.formSnapshot       = this.takeSnapshot();
        this.changeDetected     = false;
        networkRequest.payload  = new FormData(this.$refs.form)
      },
      validateChange(){
        this.changeDetected = this.takeSnapshot() !== this.formSnapshot
      },
      takeSnapshot(){
        return JSON.stringify([...(new FormData(this.$refs.form))].map(val => val[1].split(',').sort().join(',')));
      },
      onSuccess(payload){
        this.$emit('onSuccess', payload)
      },
      onSubmit(){}
    }
  }
</script>

<style>
  .form-button button {
    background: #4BCEAC !important;
    border-color: #4BCEAC !important;
    border-radius: 50px;
    padding: 3px 40px;
    min-width: 130px;
    font-size: 14px;
    margin-left: 10px;
  }
</style>
