<template>
  <input
    ref=    "master"
    type=   "checkbox"
    @click= "toggleSelection"
    :indeterminate.prop="value.selectedItems.length > 0 && value.selectedItems.length < value.sourceItems.length"
  >
</template>

<script>
  export default {
    name: 'MasterCheckbox',
    props: {
      value: Object,
    },
    data() {
      return {
      }
    },
    methods: {
      toggleSelection(){
        while (this.value.selectedItems.length > 0)
          this.value.selectedItems.pop();

        if (this.$refs.master.checked)
          this.value.sourceItems.forEach(item => this.value.selectedItems.push(item));
      }
    },
    watch: {
      'value.selectedItems': function(val){
        this.$refs.master.checked = val.length >= this.value.sourceItems.length
      }
    }
  }
</script>
<style scoped>
  .b-button {
    margin-left: 10px;
  }
</style>
