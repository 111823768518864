<template>
  <FormSubmitter :path            = "`campaigns/${campaign.id}/update`"
                 method           = "put"
                 :enabled         = "submitEnabled"
                 @onSuccess       = "updateSuccess"
                 submitLabel      = "Update"
                 :hideSubmit      = "!editMode"
                 :showPending     = "true"
                 :feedbackObject  = "feedbackObject"
  >
    <div class="form-group row justify-content-center">
      <label class="text-right col-sm-1 col-form-label">Monetization presets</label>
      <div class="col-sm-9">
        <multiselect
          label             = "name"
          @input            = "onPresetSelected"
          v-model           = "selectedPresets"
          track-by          = "name"
          :options          = "filteredPresets"
          :disabled         = "!platform || editMode || disableInput"
          :multiple         = "true"
          placeholder       = "Pick some"
          :close-on-select  = "false"
          :clear-on-select  = "false"
          :preserve-search  = "true"
          :preselect-first  = "true"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} presets selected</span>
          </template>
        </multiselect>
        <div v-for="(preset, i) in selectedPresets" :key="preset.monetization_preset_id">
          <div>
            <vx-tooltip :text="tooltipText(preset)" position="left" delay=".3s">
              <i class="fas fa-question pl-1"></i>
              <label v-if="preset.deleted_at" class="ml-4 error">[Deprecated]</label>
              <label class="col-md-8 col-form-label mt-2">{{ monetizationPresetName(preset.id) }}</label>
            </vx-tooltip>
          </div>
          <div>
            <input type="hidden" name="ab_tests[]" :value="buildValue(preset)"/>
            <input
              :min            = "0"
              :max            = "100"
              step            = "1"
              type            = "range"
              class           = "w-4/5"
              @change         = "onPercentageChange(i, $event)"
              :disabled       = "inputDisabled || disableInput"
              v-model.number  = "preset.percentage"
            />
            <input
              :min      = "0"
              :max      = "100"
              class     = "text-center ml-2"
              type      = "number"
              :value    = "preset.percentage"
              @change   = "onBoxChange(i, $event)"
              :disabled = "inputDisabled || disableInput"
            />
            %
          </div>
        </div>
      </div>
      <div class="col-sm-8 align-items-center">
        <div class="form-check form-check-inline">
          <input type="hidden" name="auto_pilot_enabled" :value="autoPilotEnabled"/>
          <b-form-checkbox :disabled="disableInput" switch size="lg" v-model="autoPilotEnabled" id="autoPilotEnabled" />
          <label class="form-check-label" for="autoPilotEnabled"
            >Enable Auto Pilot</label
          >
        </div>
      </div>
    </div>
    <div><span :class="feedbackObject.class">{{feedbackObject.text}}</span></div>
  </FormSubmitter>
</template>

<script>
import Multiselect from "vue-multiselect";
import { VIDEO_PLAYER_OPTIONS } from "../../../src/utils/values";

export default {
  name: "ABTestPicker",
  props: { campaign:  Object, platform: String, editMode: Boolean, duplicateMode: Boolean, disableInput: Boolean },
  data: function() {
    return {
      loaded:               false,
      touched:              false,
      feedbackObject:       { class: 'success', text: '' },
      selectedPresets:      [],
      monetizationPresets:  [],
      autoPilotEnabled:     true,
    };
  },

  updated() {
    this.$emit('input', this.selectedPresets);
  },

  async mounted() {
    this.getMonetizationPresets();

    this.autoPilotEnabled = this.campaign.auto_pilot_enabled ?? true

    if (this.editMode && this.$route.query.auto_assign_preset)
      await this.autoAssignPreset(this.$route.query.auto_assign_preset)

    this.$emit('toObject', () => this.toObject());
  },
  computed: {
    filteredPresets: function() {
      return this.monetizationPresets.filter(
        preset => preset.platform.toUpperCase() === this.platform.toUpperCase() && !preset.deleted_at
      );
    },
    inputDisabled: function() {
      return this.selectedPresets.length === 1
    },
    submitEnabled: function() {
      return this.touched
    }
  },
  methods: {
    async autoAssignPreset(preset_id) {
      const autoAssignedPresets = this.campaign.ab_tests.map((p) => {
        return this.buildValue({
          id: p.monetization_preset_id,
          percentage: p.monetization_preset_id == preset_id ? 100 : 0,
        });
      });

      try {
        await this.$http.put(
          `${this.host}/campaigns/${this.campaign.id}/update`,
          {
            ab_tests: { presets: autoAssignedPresets },
          }
        );
        this.$router.replace({ ...this.$router.currentRoute, query: {} });
        this.selectedPresets.forEach(preset => {
          preset.percentage = preset.id == preset_id ? 100 : 0;
        })
        const presetName = this.monetizationPresets.find(p => p.id == preset_id)?.name
        alert(`AB tests updated to 100% - ${presetName}`)
      } catch (err) {
        alert(err.response.data.error);
      }
    },
    getMonetizationPresets() {
      this.$http.get(`${this.host}/monetization_presets`, {
          params: { include_deleted: this.editMode || this.duplicateMode },
        })
        .then((res) => {
          this.monetizationPresets = res.data.map((p) => {
            return { ...p, percentage: 100 };
          });
          this.loadABTests();
        });
    },
    loadABTests() {
      if (!this.campaign.ab_tests) return

      this.campaign.ab_tests.forEach(ab => {
        const ABPreset = this.monetizationPresets.find(
          preset => preset.id === ab.monetization_preset_id
        );
        this.selectedPresets.push({
          ...ABPreset,
          percentage: ab.percentage * 100
        });
      })
    },
    tooltipText(preset) {
      return `sticky: ${preset.sticky_display} | video: ${VIDEO_PLAYER_OPTIONS[preset.video_player].text}`
    },
    onBoxChange(index, e) {
      e.target.value = Math.round(e.target.value)
      const value = e.target.value
      if (value > this.maxPercent || value < 1) {
        e.target.value = "";
      } else {
        this.selectedPresets[index].percentage = value;
      }
      this.onPercentageChange(index, e)
    },
    onPercentageChange(index, e) {
      if (this.selectedPresets.length !== 2) return;

      const otherPreset = this.selectedPresets[1 - index]
      otherPreset.percentage = 100 - Math.round(e.target.value);
    },
    monetizationPresetName(id) {
      return this.monetizationPresets.find(preset => preset.id === id).name;
    },
    onPresetSelected() {
      this.selectedPresets.forEach(
        preset => (preset.percentage = 100 / this.selectedPresets.length)
      );
    },
    updateSuccess() {
      this.feedbackObject.class = 'success'
      this.feedbackObject.text  = 'AB tests updated successfully'
    },
    toObject(){
      return this.toObj();
    },
    toObj() {
      return { 
        presets: this.selectedPresets.map(preset => ({
          monetization_preset_id: preset.id,
          percentage: preset.percentage / 100
        })),
        auto_pilot_enabled: this.autoPilotEnabled
      };
    },
    buildValue: function(preset) {
      return JSON.stringify({ monetization_preset_id: preset.id, percentage: preset.percentage / 100 })
    }
  },
  watch: {
    autoPilotEnabled: {
      handler(val) {
        this.touched = true;
      }
    },
    selectedPresets: {
      handler(val) {
        this.feedbackObject.text = ''
        if (val.length === 0) return

        if (this.loaded) this.touched = true
        else this.loaded = true
      },
      deep: true
    }
  },
  components: { Multiselect }
};
</script>
<style scoped>
  .error {
    color: red
  }
  .success {
    color: green
  }
</style>
