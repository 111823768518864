<template>
  <vs-card class="col-8" style="position: relative">
    <div class="col row d-flex justify-content-center">
      <div class="col-sm-8">
        <span v-if="!isSnapStory || serialNum === 1">
          <div class="form-group row m-12">
            <div v-if="duplicateMode" class="form-check form-check-inline">
              <input type="hidden" name="usePostFromDup" :value="usePostFromDup" />
              <b-form-checkbox switch size="lg" v-model="usePostFromDup" :id="`usePostFromDup-${bindingKey}`" />
              <label class="form-check-label" :for="`usePostFromDup-${bindingKey}`">Use post from duplicated</label>
            </div>
          </div>
          <div class="form-group row" :class="usePostFromDup ? 'disabled' : ''">
            <!--always disabled-->
            <label for class="text-right col-sm-2 col-form-label">Ad Name</label>
            <div class="col-sm-10">
              <input v-model="name" disabled type="text" class="form-control border_radius_50" />
            </div>
          </div>
          <div v-if="platform_options.ad_caption" class="form-group row" :class="usePostFromDup ? 'disabled' : ''">
            <label for class=" text-right col-sm-2 col-form-label">Caption</label>
            <div class="col-sm-10">
              <textarea :disabled="isFieldDisabled('text') || usePostFromDup" v-model="text" @change="trimExtraSpaces"
                type="text" class="form-control" />
            </div>
          </div>
          <div class="form-group row" :class="usePostFromDup ? 'disabled' : ''">
            <label for="dropzone" class="text-right col col-form-label position-unset">
              <span v-if="isSnapStory">Story</span>Creative #{{ serialNum }}
              <br />
              <div v-if="isSnapStory">
                <span v-if="serialNum < creativeCounter" @click="moveCreative(serialNum - 1, serialNum)"
                  class="btn btn-link">
                  <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                </span>
                <span v-if="serialNum > 1" @click="moveCreative(serialNum - 1, serialNum - 2)" class="btn btn-link">
                  <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
                </span>
              </div>
              <span class="creative-size" v-if="meta">Size: {{ meta.file_width }} x {{ meta.file_height }}</span>
              <span @click="!usePostFromDup && removeCreative(serialNum)" class="btn btn-light delete-creative-btn"
                :class="usePostFromDup ? 'cursor-not-allowed' : ''">
                <i class="fas fa-trash-alt" style="color: white;"></i>
              </span>
              <span v-if="signed_media" class="btn btn-light">
                <a :href="signed_media" target="_blank"><i class="fas fa-external-link"></i></a>
              </span>
            </label>
            <div class="col-sm-10">
              <vue-dropzone :data-id="bindingKey" :awss3="awss3" @vdropzone-file-added="fileUploadStarted"
                @vdropzone-success="fileUploadCompleted"
                @vdropzone-removed-file="(file, xhr, error) => logDropzone('removed-file', file, error)"
                @vdropzone-canceled="(file) => logDropzone('canceled', file)"
                @vdropzone-error="(file) => logDropzone('error', file)"
                @vdropzone-s3-upload-error="(errorMsg) => logDropzone('s3-upload-error', null, errorMsg)"
                @vdropzone-s3-upload-success="(s3ObjectLocation) => logDropzone('s3-upload-success', s3ObjectLocation)"
                :id="`dz-${bindingKey}`" ref="myVueDropzone" class="dz" :options="dropOptions()"></vue-dropzone>
            </div>
          </div>
          <div class="form-group row" :class="usePostFromDup ? 'disabled' : ''">
            <label for class="text-right col-sm-2 col-form-label">{{ platform_options.headline_name }} <span
                v-if="platform_options.restrictions.headline_max_chars"
                :class="headline.length < platform_options.restrictions.headline_max_chars ? 'valid' : 'invalid'">{{
                    headline.length
                }}/{{ platform_options.restrictions.headline_max_chars }}</span></label>
            <div class="col-sm-10">
              <input v-model="headline" :disabled="usePostFromDup"
                :maxlength="(platform_options.restrictions || {}).headline_max_chars" @change="trimExtraSpaces"
                type="text" class="form-control  border_radius_50" />
            </div>
          </div>
          <div v-if="platform_options.call_to_action" class="form-group row" :class="usePostFromDup ? 'disabled' : ''">
            <label for class="text-right col-sm-2 col-form-label">Call To Action</label>
            <div class="col-sm-10">
              <select v-model="call_to_action" class="form-control  border_radius_50" :disabled="usePostFromDup">
                <option :key="opt + bindingKey" :value="opt" v-for="opt in platform_options.call_to_action">{{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div>Link:</div>
          <div v-for="param in gurl.replace(/&/g, '^&').split('^')" v-bind:key="param">
            <div class="form-group row col-sm-10 my-0 pl-10">{{ param }}</div>
          </div>
        </span>
      </div>
    </div>
  </vs-card>
</template>
<script>
import vueDropzone from "vue2-dropzone";
import { UTILS, COMPUTED_PROPS, NAME_GENERATORS, VALIDATION } from "../../utils/campaign_form.js"
import { ilog, elog, alog } from "../../utils/logger/logger.js"

export default {
  name: "Creative",
  props: {
    creativeLibraryFilters: Object,
    moveCreative: Function,
    removeCreative: Function,
    adGUrlData: Object,
    campaignName: String,
    adsetNum: Number,
    serialNum: Number,
    bindingKey: String,
    creative: Object,
    platform_options: Object,
    isFieldDisabled: Function,
    assets: Array,
    forceDefaults: Boolean,
    isSnapStory: Boolean,
    creativeCounter: Number,
    duplicateMode: Boolean,
  },
  data: function () {
    return {
      headline: '',
      text: null,
      media_key: null,
      media_bucket: 'imgn-publishers',
      signed_media: null,
      call_to_action: null,
      meta: null,
      done: false,
      name: '',
      gurl: '',
      id: null,
      doneSpreading: false,
      commitedAssets: this.assets,
      usePostFromDup: false
    };
  },
  mounted() {
    const _this = this
    this.spreadCreative(this.creative).then(() => {
      _this.doneSpreading = true;
    })
    UTILS.setDefaults(this, 'ad', this.forceDefaults);
  },
  watch: {
    $props: {
      handler() {
        this.handleChange()
      },
      deep: true
    },
    $data: {
      handler() {
        this.handleChange()
      },
      deep: true
    }
  },
  computed: {
    awss3: function () {
      return {
        signingURL: (f) => `${this.host}/ads/presigned_post?file_name=${f.name}`,
        headers: { Authorization: localStorage.getItem("token") },
        params: {},
        sendFileToServer: false
      }
    }
  },
  methods: {
    trimExtraSpaces() {
      this.headline = this.headline.trim()
      if (this.text) this.text = this.text.trim()
    },
    handleChange() {
      if (this.doneSpreading) this.$eventHub.$emit("fieldChanged")
      if (!this.campaignName) return;
      this.name = NAME_GENERATORS.ad(this.campaignName, this.adsetNum, this.serialNum)
      this.gurl = COMPUTED_PROPS.gurl(this.adGUrlData.url, this.adGUrlData.utm_medium, this.adGUrlData.utm_source, this.campaignName, this.name, this.adGUrlData.platform)
    },
    dropOptions() {
      return {
        acceptedFiles: "image/*,video/*",
        maxFilesize: 30,
        maxFiles: 1,
        timeout: 180000,
        addRemoveLinks: true,
        autoProcessQueue: true,
        resize: function (file) {
          var info;
          info = {
            srcX: 0,
            srcY: 0,
            srcWidth: file.width,
            srcHeight: file.height,
            trgX: 0,
            trgY: 0,
            trgWidth: this.options.thumbnailWidth,
            trgHeight: parseInt(this.options.thumbnailWidth * file.height / file.width)
          }

          return info;
        },
        headers: {},
        accept: VALIDATION.file(this.platform_options)
      }
    },
    async fileUploadCompleted(file, response) {
      this.media_key = file.s3Signature.key;
      this.done = true;
      this.meta = { file_width: file.width, file_height: file.height };

      this.signed_media = await UTILS.getSignedMedia(this.$http, this.host, this.media_key, this.media_bucket)

      alog('file upload completed', `ad ${this.id || this.serialNum}`, { response, meta: this.meta, name: file.name })
      this.$eventHub.$emit("fileIsUploading", false, this.bindingKey)
    },
    fileUploadStarted: function (file) {
      if (file.manuallyAdded) return;

      alog('file upload started', `ad ${this.id || this.serialNum}`, file)
      this.$eventHub.$emit("fileIsUploading", true, this.bindingKey)
    },
    logDropzone(event, file, error) {
      switch (event) {
        case 'error':
          elog('file error', error, { name: file.name, status: file.status, size: file.size, type: file.type })
          this.$eventHub.$emit("fileIsUploading", false, this.bindingKey)
          break;
        case 'removed-file':
          alog('Dropzone removed-file', `ad ${this.id || this.serialNum}`, file.name);
          this.$eventHub.$emit("fileIsUploading", false, this.bindingKey)
          break;
        case 'canceled':
          alog('Dropzone file upload canceled', `ad ${this.id || this.serialNum}`, file.name);
          break;
        case 's3-upload-error':
          elog('Dropzone s3-upload-error', error);
          break;
        case 's3-upload-success':
          ilog(`Dropzone s3-upload-success: ${file}`);
          break;
        default:
          ilog(`unregistered dropzone event ${event}`)
      }
    },
    toObj() {
      return UTILS.clone({
        serialNum: this.serialNum,
        name: this.name,
        headline: this.headline,
        call_to_action: this.call_to_action,
        text: this.text,
        gurl: this.gurl,
        media_bucket: this.media_bucket,
        media_key: this.media_key,
        signed_media: this.signed_media,
        orig_ad_id: this.usePostFromDup ? this.id : null
      })
    },
    async spreadCreative(creative) {
      Object.keys(creative).forEach(key => {
        this[key] = creative[key];
      });
      if (this.signed_media) this.$refs.myVueDropzone.manuallyAddFile({ name: this.name, size: 100 }, this.signed_media)
    }
  },
  components: {
    vueDropzone,
  }
};
</script>
<style scoped>
span.gurl {
  word-break: break-word;
}

.vue-dropzone {
  width: 100%;
  text-align: center;
  margin-left: auto;
}

.valid {
  color: green;
}

.invalid {
  color: red;
  font-weight: bold;
}

.btn.btn-light {
  margin: 0.3rem;
}

.border_radius_50 {
  border-radius: 50px !important;
  border: 1px solid lightgray !important;
}

.delete-creative-btn {
  position: absolute;
  right: 20px !important;
  top: 5px;
  background-color: #B71727;
  border: 0;
}

.position-unset {
  position: unset;
}
</style>

<style>
#AssetsModal___BV_modal_outer_ {
  z-index: 9999999 !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
