<template>
  <div>
    <b-collapse class="d-flex" :visible="multiSelect.isOpen">
      <slot/>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    name: 'MultiSelection',
    props: {
      multiSelect: Object
    }
  }
</script>
