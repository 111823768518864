<template>
  <vs-card>
    <span
      @click="removeAdset(serialNum)"
      class="btn btn-light d-inline"
      id="trash"
    >
      <i class="fas fa-trash-alt" style="color: white"></i>
    </span>
    <div slot="header"><h4>Adsets</h4></div>
    <div v-if="isSnapStory">
      <DiscoverTile
        :platform_options="platform_options"
        :discoveryTile="metadata.discovery_tile"
        :adsetNum="serialNum"
      />
    </div>
    <div v-if="platform_options.adset_name">
      <div class="form-group row justify-content-center">
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label">Custom Name</label>
        <div class="col-sm-9">
          <input
            v-model="custom_name"
            :id="`${bindingKey}adset_custom_name`"
            class="form-control"
            @change="checkName(custom_name, 'custom_name')"
            v-on:keypress="isAlphaNumeric($event, 'custom_name')"
          />
        </div>
      </div>
    </div>

    <div v-if="isCustomConversion || platform_options.goals">
      <div
        class="form-group row justify-content-center"
        v-if="platform_options.goals"
      >
        <!--update disabled-->
        <label
          :for="`goal${bindingKey}`"
          class="text-right col-sm-1 col-form-label"
          >Goal</label
        >
        <div class="col-sm-9">
          <select
            v-model="goal"
            :id="`goal${bindingKey}`"
            class="form-control"
            :disabled="isFieldDisabled('goal', id)"
          >
            <option :value="goal" :key="goal" v-for="goal in goals">
              {{ goal }}
              {{ pixelCvCodes[goal] ? `= ${pixelCvCodes[goal]}` : "" }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="isCustomConversion"
        class="form-group row justify-content-center"
      >
        <!--update disabled-->
        <label
          :for="`${bindingKey}custom_conversion`"
          class="text-right col-sm-1 col-form-label"
          >Custom Conversions</label
        >
        <div class="col-sm-9">
          <select
            v-model="custom_conversion"
            :id="`${bindingKey}custom_conversion`"
            class="form-control"
          >
            <option
              :key="name"
              :value="value"
              v-for="(value, name) in conversionList"
            >
              {{ name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div>
      <div class="form-group row justify-content-center">
        <!--update disabled-->
        <label class="text-right col-sm-1 col-form-label">{{ bidLabel }}</label>
        <div class="col-sm-9">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">USD</div>
            </div>
            <input
              v-model="max_bid"
              :id="`max_bid${bindingKey}`"
              type="text"
              class="form-control"
              :disabled="isFieldDisabled('max_bid', id)"
              :required="isBidRequired"
              placeholder="Maximum bid"
              v-on:keypress="validateUnits"
            />
          </div>
        </div>
      </div>
      <div
        v-if="(platform_options.daily_budget || {}).adset"
        class="form-group row justify-content-center"
      >
        <!--update disabled-->
        <label class="text-right col-sm-1 col-form-label"
          >Adset Daily Budget</label
        >
        <div class="col-sm-9">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">USD</div>
            </div>
            <input
              v-model="daily_budget"
              :id="`daily_budget${bindingKey}`"
              class="form-control"
              type="text"
              required
              :disabled="isFieldDisabled('adset_budget', id)"
              placeholder="Adset Budget"
              v-on:keypress="validateUnits"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        v-if="siteBlacklistPresets.length > 1"
        class="form-group row justify-content-center"
      >
        <label for="sb_preset_id" class="text-right col-sm-1 col-form-label"
          >Site Blacklist Preset</label
        >
        <div class="col-sm-9">
          <select v-model="sb_preset_id" id="sb_preset_id" class="form-control">
            <option
              :value="sbp._id"
              :key="sbp._id"
              v-for="sbp in siteBlacklistPresets"
            >
              {{ sbp._id }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="platform === 'facebook'"
        class="form-group row justify-content-center"
      >
        <label class="text-right col-sm-2 col-form-label"
          >Attribution Window</label
        >
        <div class="col-sm-10">
          <select
            v-model="attr_window"
            class="form-control"
            :id="`${bindingKey}attr_window`"
          >
            <option :key="opt + bindingKey" v-for="opt in [1, 7]" :value="opt">
              {{ opt }}-day click
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="platform_options.age_min"
        class="form-group row justify-content-center align-items-center"
      >
        <!--update enabled-->
        <div class="col-sm-2">
          <div class="d-flex align-items-center" v-if="platform === 'facebook'">
            <label class="text-right">Automatic Placement</label>
            <div style="margin-left: 10px">
              <b-form-checkbox
                switch
                size="lg"
                v-model="auto_placement"
                :id="`${bindingKey}auto_placement`"
              />
            </div>
          </div>
        </div>
        <label class="text-right col-sm-1 col-form-label">Ages</label>
        <div class="col-sm-2">
          <select
            v-model="age_min"
            class="form-control"
            :id="`${bindingKey}age_min`"
          >
            <option
              :key="opt + bindingKey"
              v-for="opt in platform_options.age_min"
              :value="opt"
            >
              {{ opt ? opt : "Any" }}
            </option>
          </select>
        </div>
        -
        <div class="col-sm-2">
          <select v-model="age_max" class="form-control">
            <option :key="opt + bindingKey" :value="opt" v-for="opt in maxAge">
              {{ opt ? opt : "Any" }}
            </option>
          </select>
        </div>
        <label class="text-right col-sm-1 col-form-label">Gender</label>
        <div class="col-sm-2">
          <div class="toggles">
            <div
              class="toggle-button"
              :class="gender == opt.code ? 'active' : ''"
              :key="opt.code + bindingKey"
              v-for="opt in platform_options.gender"
              @click="selectOption('gender', opt.code)"
            >
              {{ opt.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row justify-content-center">
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label"
          >Device Platform</label
        >
        <div class="col-sm-9">
          <multiselect
            v-model="device_platforms"
            :id="`${bindingKey}device_platforms`"
            v-if="platform_options.platforms"
            label="name"
            track-by="name"
            @input="validateDevices"
            :options="platform_options.platforms"
            :multiple="true"
            :searchable="true"
            :disabled="auto_placement"
          />
        </div>
      </div>
      <div class="form-group row justify-content-center">
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label">Countries</label>
        <div class="col-sm-9">
          <multiselect
            v-model="countries"
            :id="`${bindingKey}countries`"
            v-if="platform_options.groupedCountryOptions"
            label="name"
            track-by="name"
            :options="countriesOptions"
            :disabled="isFieldDisabled('countries', id)"
            :multiple="true"
            group-label="name"
            :searchable="true"
            group-values="countries"
            :group-select="true"
            @search-change="filterCountries"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
          />
        </div>
      </div>
      <div
        class="form-group row justify-content-center"
        v-if="(platform_options.locales || []).length > 1"
      >
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label">Languages</label>
        <div class="col-sm-9">
          <multiselect
            v-model="locales"
            :id="`${bindingKey}locales`"
            label="name"
            track-by="name"
            :multiple="true"
            :searchable="true"
            :options="platform_options.locales"
          ></multiselect>
        </div>
      </div>
      <div
        class="form-group row justify-content-center"
        v-if="(platform_options.browsers || []).length > 0"
      >
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label">Browsers</label>
        <div class="col-sm-9">
          <multiselect
            v-model="browsers"
            :id="`${bindingKey}browsers`"
            label="name"
            track-by="name"
            :multiple="true"
            :searchable="true"
            :options="platform_options.browsers"
          ></multiselect>
        </div>
      </div>
      <div
        class="form-group row justify-content-center"
        v-if="(platform_options.operating_systems || []).length > 0"
      >
        <!--update enabled-->
        <label class="text-right col-sm-1 col-form-label"
          >Operating Systems</label
        >
        <div class="col-sm-9">
          <multiselect
            v-model="operating_systems"
            :id="`${bindingKey}operating_systems`"
            label="name"
            track-by="name"
            :multiple="true"
            :searchable="true"
            :options="platform_options.operating_systems"
          ></multiselect>
        </div>
      </div>
      <div
        class="form-group row justify-content-center"
        v-if="(adsetAudiences || []).length > 0"
      >
        <label class="text-right col-sm-1 col-form-label"
          >Selected Audiences</label
        >
        <div class="col-sm-9">
          <multiselect
            v-model="audiences"
            :id="`${bindingKey}audiences`"
            track-by="uid"
            :options="adsetAudiences"
            :multiple="true"
            :disabled="false"
            :clearOnSelect="false"
            :closeOnSelect="false"
          />
        </div>
      </div>
      <div
        class="form-group row justify-content-center"
        v-if="platform === 'facebook'"
      >
        <label class="text-right col-sm-1 col-form-label"
          >Schedule start time to the following midnight</label
        >
        <div class="col-sm-9">
          <select
            v-if="platform === 'facebook'"
            v-model="startDiffHours"
            class="form-control"
          >
            <option selected value=""></option>
            <option value="0">Start tomorrow</option>
            <option value="24">Start in 2 days</option>
            <option value="48">Start in 3 days</option>
          </select>
        </div>
      </div>
    </div>
    <Creatives
      :adsetNum="serialNum"
      :adGUrlData="adGUrlData"
      :loadedData="adset.creatives"
      :campaignName="campaignName"
      :initialAdCount="adset.initialAdCount"
      :isFieldDisabled="isFieldDisabled"
      :platform_options="platform_options"
      :creativeLibraryFilters="creativeLibraryFilters"
      :isSnapStory="isSnapStory"
      :duplicateMode="duplicateMode"
    />
  </vs-card>
</template>
<script>
import Multiselect from "vue-multiselect";
import {
  UTILS,
  SERIALIZE,
  VALIDATION,
  DEFAULT_VALS,
  COMPUTED_PROPS,
  NAME_GENERATORS,
  CUSTOM_CONVERSION,
  FB_ATTR_WINDOW_DEFAULT,
} from "../../utils/campaign_form.js";
import { PIXEL_CV_CODES } from "../../utils/product_entities.js";
import Creatives from "./Creatives.vue";
import DiscoverTile from "./DiscoverTile.vue";

export default {
  name: "Adset",
  props: {
    campaignName: String,
    adset: Object,
    serialNum: Number,
    bindingKey: String,
    removeAdset: Function,
    isBidRequired: Boolean,
    platform_options: Object,
    adGUrlData: Object,
    goals: Array,
    platform: String,
    adsetAudiences: Array,
    conversionList: Object,
    isCustomConversion: Boolean,
    isFieldDisabled: Function,
    creativeLibraryFilters: Object,
    isSnapStory: Boolean,
    customParams: Object,
    duplicateMode: Boolean,
  },
  data: function () {
    return {
      id: null,
      name: "",
      goal: null,
      gender: null,
      age_min: null,
      age_max: null,
      attr_window: FB_ATTR_WINDOW_DEFAULT,
      max_bid: null,
      locales: [],
      browsers: [],
      metadata: (this.adset || {}).metadata || {},
      audiences: [],
      countriesOptions: [],
      countries: [],
      external_id: null,
      custom_name: "",
      daily_budget: null,
      pixelCvCodes: PIXEL_CV_CODES,
      sb_preset_id: null,
      auto_placement: false,
      device_platforms: [],
      operating_systems: [],
      custom_conversion: {},
      siteBlacklistPresets: [{ _id: "None" }],
      startDiffHours: "",
    };
  },
  mounted() {
    this.spreadAdset(this.adset);
    this.audiences = this.adsetAudiences.concat(this.origAudiences || []);
    UTILS.setDefaults(this, "adset");
    this.getPlatformData(this.platform);
    this.countriesOptions = this.platform_options.groupedCountryOptions;
  },
  watch: {
    platform: function (newVal) {
      this.max_bid = DEFAULT_VALS.get(newVal, "bid");
      this.age_min = DEFAULT_VALS.get(newVal, "age_min");
      this.locales = DEFAULT_VALS.get(newVal, "locales");
      this.browsers = DEFAULT_VALS.get(newVal, "browsers");
      this.daily_budget = DEFAULT_VALS.get(newVal, "adset_budget");
      this.auto_placement = false;
      this.operating_systems = DEFAULT_VALS.get(newVal, "operating_systems");
      this.countriesOptions = this.platform_options.groupedCountryOptions;
      this.getPlatformData(newVal);
      UTILS.setDefaults(this, "adset");
    },
    isCustomConversion: function (val) {
      if (!val) this.custom_conversion = {};
    },
    $data: {
      handler() {
        this.name = NAME_GENERATORS.adset(this);
        this.$eventHub.$emit("fieldChanged");
        this.customParams.isPurchase = this.isPurchaseConversion;
      },
      deep: true,
    },
    age_min: function (val) {
      if (val === "Any") this.age_max = "Any";
    },
    auto_placement: function (n) {
      if (n) this.device_platforms = [];
    },
  },
  computed: {
    maxAge: function () {
      return COMPUTED_PROPS.maxAge(this.platform_options.age_max, this.age_min);
    },
    isPurchaseConversion: function () {
      return CUSTOM_CONVERSION.isPurchase(this);
    },
    bidLabel: function () {
      return this.isPurchaseConversion ? "Min ROAS (0.01 - 1000.0)" : "Max bid";
    },
  },
  methods: {
    selectOption(field, value) {
      this[field] = value;
    },
    filterCountries(query) {
      const groupFilter = this.platform_options.groupedCountryOptions.filter(
        (group) => group.name.toLowerCase().includes(query.toLowerCase())
      );
      if (groupFilter.length > 0) {
        this.countriesOptions = groupFilter;
        return;
      }

      this.countriesOptions = this.platform_options.groupedCountryOptions.map(
        (group) => {
          const groupClone = JSON.parse(JSON.stringify(group));
          groupClone.countries = group.countries.filter((country) =>
            country.name.toLowerCase().includes(query.toLowerCase())
          );
          return groupClone;
        }
      );
    },
    getPlatformData(platform) {
      if (platform === "taboola") this.getSiteBlacklistsPresets(platform);
    },
    getComponent(name) {
      //inside vs-card
      return this.$children[0].$children.find((c) => c.$options.name === name);
    },
    getSiteBlacklistsPresets(platform) {
      this.$http
        .get(`${this.host}/site_blacklist_presets/index/${platform}`)
        .then((res) => {
          this.siteBlacklistPresets = this.siteBlacklistPresets.concat(
            res.data
          );
          this.sb_preset_id = "None";
        });
    },
    validateUnits(e) {
      e = e ? e : window.event;
      if (!e.key.match(/\d+|[.]/i)) e.preventDefault();
    },
    checkName: function (model, element) {
      if (model === "") return;

      let regExp = element.includes("adName")
        ? /^[a-z0-9\-_]+$/i
        : /^[a-z0-9\-_ ]+$/i;
      if (model.match(regExp) === null)
        document
          .getElementById(element)
          .setCustomValidity("You have non alpha numeric values");
      else document.getElementById(element).setCustomValidity("");
    },
    isAlphaNumeric: function (evt, type) {
      let regExp = type.includes("adName")
        ? /^[a-z0-9\-_]+$/i
        : /^[a-z0-9\-_ ]+$/i;
      let charCode = evt.charCode;
      if (String.fromCharCode(charCode).match(regExp) !== null) return true;
      else evt.preventDefault();
    },
    validateDevices() {
      this.device_platforms = VALIDATION.device_platforms(
        this.device_platforms
      );
    },
    toObj() {
      //clean old campaigns:

      return UTILS.clone({
        id: this.id,
        goal: this.goal,
        name: this.name,
        gender: this.gender,
        max_bid: this.max_bid,
        age_max: this.age_max,
        age_min: this.age_min,
        attr_window: this.attr_window,
        locales: (this.locales || []).map((c) => c.code),
        browsers: (this.browsers || []).map((c) => c.code),
        metadata: this.metadata,
        creatives: this.getComponent("Creatives").toObj(),
        serialNum: this.serialNum,
        audiences: this.audiences,
        countries: (this.countries || []).map((c) => c.code),
        custom_name: this.custom_name,
        daily_budget: (this.platform_options.daily_budget || {}).adset
          ? this.daily_budget
          : null,
        sb_preset_id: this.sb_preset_id,
        auto_placement: this.auto_placement,
        device_platforms: (this.device_platforms || []).map((c) => c.code),
        operating_systems: (this.operating_systems || []).map((c) => c.code),
        custom_conversion: this.custom_conversion,
        start_time: this.startDiffHours,
      });
    },
    async spreadAdset(adset) {
      this.id = adset.id;
      this.goal = adset.goal;
      this.gender = adset.gender;
      this.max_bid = adset.max_bid;
      this.age_max = adset.age_max;
      this.age_min = adset.age_min;
      this.attr_window = adset.attr_window || FB_ATTR_WINDOW_DEFAULT;
      this.metadata = adset.metadata || { discovery_tile: {} };
      this.custom_name = adset.custom_name;
      this.external_id = adset.external_id;
      this.daily_budget = adset.daily_budget;
      this.sb_preset_id = adset.sb_preset_id;
      this.auto_placement = adset.auto_placement;
      this.custom_conversion = adset.custom_conversion || {};

      if (adset.start_time != null) {
        const startTimeDiff =
          new Date(adset.start_time).getTime() - new Date().getTime();
        const diffInHours = startTimeDiff / (1000 * 3600);

        if (diffInHours <= 24) this.startDiffHours = "0";
        else if (diffInHours > 24 && diffInHours <= 48)
          this.startDiffHours = "24";
        else if (diffInHours > 48) this.startDiffHours = "48";
      }

      this.locales = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.locales
      );
      this.browsers = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.browsers
      );
      this.countries = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.countries
      );
      this.device_platforms = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.device_platforms
      );
      this.operating_systems = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.operating_systems
      );
    },
  },
  components: {
    Multiselect,
    Creatives,
    DiscoverTile,
  },
};
</script>

<style lang="scss">
.toggles {
  display: flex;

  .toggle-button {
    margin-right: 8px;
    margin-right: 8px;
    border: 1px solid #4bceac;
    padding: 0.3rem 0.7rem;
    border-radius: 19px;
    color: #4bceac;
    cursor: pointer;

    &.active {
      color: white;
      border-color: white;
      background-color: #4bceac;
    }
  }
}

#trash {
  background-color: #b71727;
  float: right;
}
</style>
<style>
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-size: 2rem;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #ffffff;
}

.dz {
  margin-bottom: 1rem;
}

.creative-size {
  font-weight: bold;
}

.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}

.alert-danger {
  white-space: pre-line;
}

.submit-hr {
  margin: 3rem;
}

button.update-btn {
  line-height: 0.8rem;
  padding: 0.3rem 0.2rem;
  font-size: inherit;
  margin-right: 1rem;
}

div div.creatives-update-container {
  display: flex;
  justify-content: center;
}

span.gurl {
  word-break: break-word;
}

.move-left {
  width: auto;
  box-shadow: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
