<template>
  <div>
    <div class="custom-filter">
      <b-button class="btn-sm mx-1 btn-purple" variant="outline-primary" @click="addFilter" :disabled="supportedFilters.length === 0">Add filter</b-button>
      <b-button class="btn-sm mx-1 btn-purple" variant="outline-primary" @click="customFilters.splice(0, customFilters.length); $emit('filterApplied')" :disabled="customFilters.length === 0">Clear</b-button>
      <slot></slot>
    </div>
    <div class="search-results">
      <template v-for="(filter, i) in customFilters">
        <div class="p-1 d-flex" v-bind:key="i">
          <select class="mr-4 btn-purple pl-5" v-model="filter.type">
            <option :value="type" v-for="type in Object.keys(supportedFilters)" v-bind:key="type + i">{{ type }}</option>
          </select>
          <select class="mr-4 btn-purple pl-5" v-model="filter.left_operand">
            <option :value="lo" v-for="lo in supportedFilters[filter.type].leftOperands" v-bind:key="lo + i">{{ supportedFilters[filter.type].labels[lo] || lo }}</option>
          </select>
          <select class="mr-4 btn-purple pl-5" v-model="filter.operator">
            <option :value="op" v-for="op in supportedFilters[filter.type].operators" v-bind:key="op + i">{{ op }}</option>
          </select>
          <input v-model="filter.right_operand" type="text" v-on:keyup.enter="$emit('filterApplied')"/>
          <i class="fas fa-times mr-4 remove-filter" @click="customFilters.splice(i, 1)"/>
          <b-button class="btn-sm mx-1 btn-purple" variant="primary" @click="$emit('filterApplied')" :disabled="customFilters.length === 0">Apply filter</b-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CustomFilter',
    props: {
      customFilters:    Array,
      feedbackObject:   Object,
      supportedFilters: Object
    },
    data() {
      return {
      }
    },
    methods: {
      addFilter(){
        let type = Object.keys(this.supportedFilters)[0];
        let supportedFilter = this.supportedFilters[type];
        this.customFilters.push(
          {
            type:           type,
            left_operand:   supportedFilter.leftOperands[0],
            operator:       supportedFilter.operators[0],
            right_operand:  supportedFilter.rightOperands,
          }
        )
      }
    },
    watch: {
    }
  }
</script>
<style scoped>
  .remove-filter{
    padding:    0.25rem;
    color:      red;
    cursor:     pointer;
    font-size:  1rem;
  }

  .btn-purple {
    background: #696FFC;
    color: white;
    border-radius: 50px;
    padding: 1px 0px;
    min-width: 130px;
    font-size: 14px;
  }

  .search-results input {
    background: #F1F1F1;
    border: 0;
    border-radius: 50px;
    padding: 2px 0 2px 10px;
    width: 260px;
    color: grey;
  }
</style>
